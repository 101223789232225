import { afterNextRender, ApplicationRef, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FirebaseService } from './web/services/firebase.service';
import { push, ref } from 'firebase/database';
 var AOS:any
AOS?.init()
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Splintzer Technologies';

constructor(private fire:FirebaseService){
 

 
}
ngOnInit(){
    this.gt()
  return true 
}

async gt(){
  let d = new Date()
  let a = await  push(ref(this.fire.getDatabase(),'/analytics/views/landing/'+d.getFullYear()+'/'+d.getMonth()+'/'+d.getDate()),true)

}

}
